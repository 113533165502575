#root {
    position: relative;
    min-height:100%;
    display: flex;
    flex-direction: column;
}

#wrapper {
    text-align: center;
    font-size: 2rem;

    display: flex;
    flex-direction: column;
    align-items: center;

    margin-top: auto;
    margin-bottom: auto;
}

.inner-container {
    max-width: 60vw;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
}

.inner-container > div {
    box-sizing: border-box;
    padding: 5rem 3rem;
    border: 0.25rem solid #f26535;
    text-shadow: 1px 5px #f2653525;
}

.footer {
    text-align: center;
    font-size: 1.25rem;
}

.page-title {
    margin-top: auto;
}

@media only screen and (max-width: 1024px) {
    #wrapper {
        font-size: 24px;
        line-height: 1.25;
    }

    .inner-container {
        max-width: 95%;
        margin: 0 auto;
    }

    .inner-container > div {
        padding: 3rem 1rem;
        border: 2px solid #f26535;
    }
}
